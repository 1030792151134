<template>
    <el-card class="box-card" sytle="padding: 5px;">
        <!-- 表单只有一个文本框的时候，按回车会自动submit表单，需要禁用sunmit -->
        <el-form
            ref="searchform"
            :inline="true"
            :model="form"
            @submit.native.prevent
            size="medium"
        >
            <el-form-item label="玩家" prop="staff_name">
                <el-input
                    v-model="form.staff_name"
                    placeholder="玩家搜索"
                    @keyup.enter.native="search()"
                    clearable
                    class="staff_name_c"
                ></el-input>
            </el-form-item>
            <el-form-item label="日期">
                <el-date-picker
                    v-model="form.start_time"
                    type="date"
                    value-format="yyyy-MM-dd"
                    placeholder="所有时间"
                    class="time_c">
                </el-date-picker>
                至
                <el-date-picker
                    v-model="form.end_time"
                    type="date"
                    value-format="yyyy-MM-dd"
                    placeholder="所有时间"
                    class="time_c">
                </el-date-picker>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" plain @click="search">搜索</el-button>
                <el-button type="warning" plain @click="resetbtn()">重置</el-button>
            </el-form-item>
        </el-form>
    </el-card>
</template>

<script>
export default {
    name: 'ScoreSearchBarComponent',
    data() {
        return {
            form: {
                staff_name: '',
                start_time: '',
                end_time: '',
                page: 1
            }
        }
    },
    computed: {},
    methods: {
        search() {
            this.$emit('search', this.form)
        },
        resetbtn() {
            this.form = {
                staff_name: '',
                start_time: '',
                end_time: '',
                page: 1
            }
        }
    },
    filters: {},
    props: {},
    created() {},
    destoryed() {},
    mounted() {},
    updated() {},
    components: {},
    watch: {},
}
</script>

<style lang='less' scoped>
.box-card .el-form-item {
    margin-bottom: 3px;
}
.staff_name_c {
    width: 200px;
}
.time_c {
    width: 150px;
}
</style>