<template>
    <div>
        <score-search-bar-component
        @search="get_score_index"
        ></score-search-bar-component>
        <el-card class="box_card" v-loading.fullscreen.lock="loading">
            <el-table
                :data="table_data"
                style="width: 100%"
                border
                stripe
                highlight-current-row
                @sort-change="sort_change"
            >
                <el-table-column
                    align="center"
                    type="index"
                    min-width="10px">
                </el-table-column>
                <el-table-column
                    align="center"
                    prop="create_time"
                    label="日期"
                    min-width="170px"
                    sortable="custom">
                </el-table-column>
                <el-table-column
                    align="center"
                    prop="content"
                    label="变动说明"
                    min-width="270px">
                </el-table-column>
                <el-table-column
                    align="center"
                    prop="score"
                    label="积分变动"
                    min-width="110px"
                    sortable="custom"
                >
                    <template slot-scope="scope">
                        <span v-if="scope.row.score>0" style="font-family: HarmonyOS_Sans_Black;color: #2828FF;">{{ '+ '+scope.row.score }}</span>
                        <span v-else style="font-family: HarmonyOS_Sans_Black;color: #FF0000;">{{ scope.row.score }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    align="center"
                    prop="total"
                    label="剩余积分"
                    min-width="110px"
                    sortable="custom"
                >
                    <template slot-scope="scope">
                        <span style="font-family: HarmonyOS_Sans_Black;color: #9F35FF;">{{ scope.row.total }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    align="center"
                    prop="create_staff_name"
                    label="玩家"
                    min-width="180px">
                </el-table-column>
            </el-table>
        </el-card>
        <common-page-component
            :current_page="cond.page"
            :page_size="cond.limit"
            :total="total"
            @search="get_score_index"
        ></common-page-component>
    </div>
</template>

<script>
import { UserScoreIndexRequest } from '@/network/query/score.js'

import ScoreSearchBarComponent from '@/components/score/ScoreSearchBarComponent'
import CommonPageComponent from '@/components/common/CommonPageComponent'

export default {
    name:'',
    data(){
        return {
            loading: true,
            cond: {
                staff_name: '',
                start_time: '',
                end_time: '',
                page: 1,
                limit: 10
            },
            total: 0,
            table_data: []
        }
    },
    computed:{},
    methods:{
        get_score_index(param = {}) {

            this.loading = true
            this.cond.staff_name = param.staff_name ?? this.cond.staff_name
            this.cond.start_time = param.start_time ?? this.cond.start_time
            this.cond.end_time = param.end_time ?? this.cond.end_time
            this.cond.page = param.page ?? this.cond.page
            if (param.limit) {
                this.cond.limit = param.limit
                this.cond.page = 1
            }

            if (param.order) {
                this.cond.order = param.order
                this.cond.page = 1
            }

            UserScoreIndexRequest(this.cond)
                .then((s) => {
                    this.loading = false
                    if (s.status === 0) {
                        this.table_data = s.result.data
                        this.total = s.result.total
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
            
            //滚动条回顶部
            this.$nextTick(() => {
                document.getElementById('el_main').scrollTop = 0;
            })
        },
        sort_change(column) {
            const obj = {order: column['order'],prop: column['prop']}
            this.get_score_index({order: obj})
        }
    },
    filters:{},
    props:{},
    created(){
        this.get_score_index()
    },
    beforeDestroy(){},
    mounted(){},
    updated(){},
    components:{
        ScoreSearchBarComponent,
        CommonPageComponent
    },
    watch:{},
}
</script>

<style lang='less'></style>